import { useState } from "react";
import { Box, SimpleGrid, Spacer, Heading, Stack } from "@chakra-ui/layout";
import { Tag, TagCloseButton, TagLabel } from "@chakra-ui/tag";
import { RiSearchLine } from "react-icons/ri";
import meds from "./meds.json";

import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  Text,
  InputGroup,
  InputLeftElement,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
  AutoCompleteProps,
} from "@choc-ui/chakra-autocomplete";
const sortedMeds = meds.sort((a, b) => a.name.localeCompare(b.name));

function App() {
  const [inputKey, setInputKey] = useState(0);
  const clearInput = () => setInputKey((prev) => prev + 1);

  const [selected, setSelected] = useState<Array<string>>([]);

  function addSelected(value: string) {
    setSelected((prev) => [...prev, value]);
  }

  function removeSelected(value: string) {
    setSelected((prev) => prev.filter((item) => item !== value));
  }

  function reset() {
    setSelected([]);
  }

  const onSelectOption: AutoCompleteProps["onSelectOption"] = (item) => {
    addSelected(item.item.originalValue);
    clearInput();
  };

  return (
    <>
      <Flex
        minWidth="max-content"
        alignItems="center"
        gap="2"
        padding={3}
        bg="gray.100"
      >
        <Box p="2">
          <Heading size="md">vetmeds.io</Heading>
        </Box>
        <Spacer />
        <ButtonGroup>
          <Button variant="ghost">Sign in</Button>
          <Button variant="ghost">Pricing</Button>
        </ButtonGroup>
      </Flex>

      <Flex pt={[10, 10, 20]} px={10} justify="center" align="center" w="full">
        <SimpleGrid columns={[1, 1, 2]} spacing={10} w="100%">
          <Box>
            <FormControl>
              <AutoComplete
                key={inputKey}
                openOnFocus
                onSelectOption={onSelectOption}
              >
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <RiSearchLine opacity={0.3} />
                  </InputLeftElement>
                  <AutoCompleteInput
                    autoFocus={true}
                    placeholder="Enter a medication"
                    w="100%"
                  />
                </InputGroup>

                <AutoCompleteList>
                  {sortedMeds
                    .map((med) => med.name)
                    .filter((med) => !selected.includes(med))
                    .map((med) => (
                      <AutoCompleteItem
                        key={`option-${med}`}
                        value={med}
                        textTransform="capitalize"
                      >
                        {med}
                      </AutoCompleteItem>
                    ))}
                </AutoCompleteList>
              </AutoComplete>
            </FormControl>
          </Box>

          <Stack>
            <Box
              p="4"
              border="1px"
              borderColor="gray.200"
              borderRadius="md"
              textAlign="center"
            >
              {selected.length === 0 && <Text>No medications selected</Text>}

              {selected.map((item) => (
                <Tag
                  key={item}
                  m="1"
                  size="lg"
                  cursor="pointer"
                  _hover={{ opacity: 0.8, textDecoration: "line-through" }}
                  onClick={() => removeSelected(item)}
                >
                  <TagLabel>{item}</TagLabel>
                  <TagCloseButton />
                </Tag>
              ))}

              {selected.length > 0 && (
                <Box>
                  <Button variant="link" onClick={reset}>
                    Reset
                  </Button>
                </Box>
              )}
            </Box>

            {selected.length < 2 && (
              <Alert status="info">
                <AlertIcon />
                Select at least two medications to check for interactions.
              </Alert>
            )}

            {selected.length >= 2 && (
              <Alert status="success">
                <AlertIcon />
                There are no known interactions between the selected
                medications.
              </Alert>
            )}
          </Stack>
        </SimpleGrid>
      </Flex>
    </>
  );
}

export default App;
